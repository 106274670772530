export default [
  {
    name: "Detox Boilerplate",
    description: "Sample React Native project using Detox for testing",
    links: [
      {
        name: "Github",
        url: "https://github.com/klalka-dev/detox-boilerplate"
      }
    ]
  },
  {
    name: "WebdriverIO TestRail Reporter",
    description:
      "Custom reporter for WebdriverIO that automatically writes results to TestRail",
    links: [
      {
        name: "Github",
        url: "https://github.com/klalka-dev/wdio-testrail-reporter.git"
      }
    ]
  }
];
