export default [
  {
    title: "Testing React Native Applications with Detox",
    date: "2019-01-01 12:00:00",
    description: "Using Detox to test a React Native application",
    links: [
      {
        name: "Medium",
        url: "https://www.medium.com/klalka-dev/detox-react-native"
      }
    ]
  }
];
