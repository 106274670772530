import React, { useState } from "react";
import "./App.css";

import { Bio, Blog, Projects } from "./pages";

function App() {
  const [page, setPage] = useState("Bio");
  const [isLightTheme, setLightTheme] = useState(true);
  const sections = ["Bio", "Projects", "Blog"];

  function getContent() {
    switch (page) {
      case "Bio":
        return <Bio />;
      case "Projects":
        return <Projects />;
      case "Blog":
        return <Blog />;
      default:
        return <p>Page State Error. Clear cache and reset site.</p>;
    }
  }

  return (
    <div className="App" data-theme={isLightTheme ? "light" : "dark"}>
      <div className="header">
        <h1>KevinLalka.com</h1>
        <div onClick={() => setLightTheme(!isLightTheme)}>
          {`Go ${isLightTheme ? "Dark" : "Light"}`}
        </div>
      </div>
      <main className="main">{getContent()}</main>
      <header className="footer">
        {sections.map(section => (
          <div
            className={`footer-item ${page === section ? "active" : ""}`}
            onClick={() => setPage(section)}
          >
            {section}
          </div>
        ))}
      </header>
    </div>
  );
}

export default App;
