import React, { useState } from "react";
import projects from "../data/projects";

export default function Projects() {
  const [currentProject, setCurrentProject] = useState(0);
  const { description, name, links } = projects[currentProject];

  function decreaseProjectIndex() {
    if (currentProject > 0) {
      return setCurrentProject(currentProject - 1);
    } else {
      return setCurrentProject(0);
    }
  }

  function increaseProjectIndex() {
    if (currentProject === projects.length - 1) {
      setCurrentProject(currentProject);
    } else {
      setCurrentProject(currentProject + 1);
    }
  }

  return (
    <div className="card">
      <div className="card-content">
        <h2>{name}</h2>
        <div>{description}</div>
        <div>
          Hosted on{" "}
          <a target="_" href={links[0].url}>
            {links[0].name}
          </a>
        </div>
      </div>
      <div className="pagination-bar">
        <div className="nav-btn" onClick={decreaseProjectIndex}>
          {currentProject !== 0 && "<"}
        </div>
        <div className="pagination-data">
          {`${currentProject + 1} / ${projects.length}`}
        </div>
        <div className="nav-btn" onClick={increaseProjectIndex}>
          {currentProject !== projects.length - 1 && ">"}
        </div>
      </div>
    </div>
  );
}
