import React from "react";

export default function Bio() {
  return (
    <div>
      <p>Hello, my name is Kevin Lalka</p>
      <p>While you are here you can see all of my public projects and blogs.</p>
      <p>Enjoy!</p>
    </div>
  );
}
