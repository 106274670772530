import React, { useState } from "react";
import blogs from "../data/blogs";

export default function Blog() {
  const [currentBlog, setCurrentBlog] = useState(0);
  const { description, links, title } = blogs[currentBlog];

  function decreaseBlogIndex() {
    if (currentBlog > 0) {
      return setCurrentBlog(currentBlog - 1);
    } else {
      return setCurrentBlog(0);
    }
  }

  function increaseBlogIndex() {
    if (currentBlog === blogs.length - 1) {
      setCurrentBlog(currentBlog);
    } else {
      setCurrentBlog(currentBlog + 1);
    }
  }

  return (
    <div className="card">
      <div className="card-content">
        <h2>{title}</h2>
        <div>{description}</div>
        <div>
          Hosted on{" "}
          <a target="_" href={links[0].url}>
            {links[0].name}
          </a>
        </div>
      </div>
      <div className="pagination-bar">
        <div className="nav-btn" onClick={decreaseBlogIndex}>
          {currentBlog !== 0 && "<"}
        </div>
        <div className="pagination-data">{`${currentBlog + 1} / ${
          blogs.length
        }`}</div>
        <div className="nav-btn" onClick={increaseBlogIndex}>
          {currentBlog !== blogs.length - 1 && ">"}
        </div>
      </div>
    </div>
  );
}
